/* We define the dimensions without unit to calculate and apply unit while exporting */
const SITE_MIN_WIDTH = '100%';
const SIDEBAR_WIDTH = '226';
const OPEN_SEARCH_CLICKABLE_WIDTH = 26;
const OPEN_SEARCH_WIDTH = 210;

const RELATIVE_UNIT_BASE = 16;

const breakpoints = {
  tablet: 960,
  phone: 768,
};

const generateBreakpoint = (width, cssMarkup) => `
@media (max-width: ${width / RELATIVE_UNIT_BASE}em) {
  ${cssMarkup};
  }
`;

export default {
  siteMinWidth: `${SITE_MIN_WIDTH}px`,
  sidebarWidth: `${SIDEBAR_WIDTH}px`,
  openSearchClickableLeft: `${OPEN_SEARCH_WIDTH -
    OPEN_SEARCH_CLICKABLE_WIDTH}px`,
  openSearchWidth: `${OPEN_SEARCH_WIDTH}px`,
  generateBreakpoint,
  media: Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = (...args) => generateBreakpoint(breakpoints[label], args);

    return acc;
  }, {}),
};