import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { PlayerSelector, Input, Schedules, Button } from '../../components'
import styled, { css } from 'styled-components'
import CurrencyFormat from 'react-currency-format';
import * as yup from 'yup'
import { withFormik } from 'formik'
import useQueryData from '../../hooks/useQueryData'
import moment from 'moment'
import 'moment/min/locales'
import { api } from '../../api';

const COST_PER_PERSON = 295;

const CustomCssInput = styled.input`
    background-color: ${({theme}) => theme.colors.dark} !important;
    border-radius:0 !important;
    font-size:15px !important;
    height : 40px !important;
    color : ${({theme}) => theme.colors.yellow} !important;
    box-shadow:none !important;
    border:0 !important;
    border-bottom:1px solid ${({theme}) => theme.colors.white} !important;
    width:100%;
`

const CustomCol = styled(Col)`
    text-align:right;
    @media (max-width: 48em) {
        text-align:center;
        margin-top:20px;
    }
`

const Label = styled.label`
    color : ${({theme}) => theme.colors.white};
    font-size:15px;
    text-transform:uppercase;
    padding-right:1rem;

    ${({big}) => big && css`
        font-size:28px;
        font-weight:bold;
        color : ${({theme}) => theme.colors.yellow};
        paddingLeft : 0.9em;

        @media (max-width: 48em) {
           padding:20px 0;
        }
    `}

    ${({disclaimer}) => disclaimer && css`
        font-size:15px;
        font-style:italic;
        padding:0;
        text-transform:none !important;
        color : ${({theme}) => theme.colors.white};
    `}
`

const ContainerWrapper = styled(Container)`
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;

    @media (max-width: 48em) {
        padding:20px !important;
    }
`;

const RowS = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    padding-bottom:0.5rem;
    width:100%;

    ${({sb}) => sb && css`
        justify-content:space-between;
    `}

    ${({price}) => price && css`
        @media (max-width: 48em) {
            flex-direction:row !important;
            justify-content:space-between !important;
        }
    `}

    @media (max-width: 48em) {
        flex-direction:column;
    }

    ${({players}) => players && css`
        @media (max-width: 48em) {
            flex-direction:row !important;
        }
    `}
`

const ReservaForm = ({
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched
}) => {
    moment.locale('es')


    const [canCheckQuortesy, setQuortesyEvent] = useState(true) 

    const [{data,loading,error}] = useQueryData({
        endpoint : `rooms/getAll/1/${moment(values.booking_date).format('Y-MM-DD')}`,
        trigger : `${values.booking_date}`
    })

    const [{data : data2}] = useQueryData({
        endpoint : '/traduction/11/1',
        trigger : null
    })

    const booking_message = data2 && data2.result && data2.result.reserva_message || "Espere por favor ..."

    const [ schedules, setSchedules ] = useState([])

    const rooms = data && data.result || []

    const findRoomSchedules = id => {
        const schedules = rooms.find((room) => room.room_id == id)
        
        if(schedules.blocks.length > 0){
            setSchedules(schedules.blocks[0].hours)
        }
    }

    const CustomInput = ({ value, onClick }) => (
        <CustomCssInput onClick={onClick} value={value} />
      );

    const checkGiftCard = () => {
        const giftCard = values.quortesy
        if(giftCard){
            api.get(`giftcards/unique/${giftCard}`)
            .then(({data}) => {
                const result = data.result

                let cardType = result.giftcard_type,
                cardId = result.giftcard_id,
                isAvailable = result.giftcard_available,
                remainingUses = result.giftcard_remaining_uses,
                uses = result.giftcard_uses,
                availableDate = result.giftcard_available_date

                const players = values.players
                const conf = window.confirm("La cortesía se aplicará, desea continuar?")
                const booking_price = parseFloat(values.booking_price.replace('$',''))

                let canGo = false

                if(isAvailable){
                    if(remainingUses > 0 && uses == 0 && availableDate == "0000-00-00"){
                        cardType = 'unlimited'
                    }

                    if(cardType == 'temp'){
                        if(remainingUses > 0 && moment(availableDate) > moment()){
                            canGo = true
                        }
                    }

                    if(
                        !giftCard.includes('FREE') && 
                        !giftCard.includes('EUFO') &&
                        !giftCard.includes('VENT') &&
                        !giftCard.includes('GIFT')
                    ){

                        if(cardType == 'free'){
                            if(conf){
                                setFieldValue('booking_price','0.00')
                                setQuortesyEvent(false)
                            }
                        }

                        if(cardType == '1-person'){
                            if(conf){
                                const newPrice = (parseFloat(booking_price) - 200)
                                setFieldValue('booking_price',newPrice)
                                setQuortesyEvent(false)
                            }
                        }

                        if(cardType == '2-person'){
                            if(conf){
                                const newPrice = (parseFloat(booking_price) - 400)
                                setFieldValue('booking_price',newPrice)
                                setQuortesyEvent(false)
                            }
                        }

                        if(cardType == "half-price"){
                            if(conf){
                                const newPrice = (parseFloat(booking_price) / 2)
                                setFieldValue('booking_price',newPrice)
                                setQuortesyEvent(false)
                            }
                        }

                    }else{
                        canGo = true
                    }

                    if(canGo){
                        if(players >= 4){
                            //tarjetas de regalo temporales
                            if(
                                giftCard.includes('VENT') ||
                                giftCard.includes('EUFO')
                            ){
                                if(conf){
                                    if(players == 4){
                                        const newPrice = (parseFloat(booking_price) - 260)
                                        setFieldValue('booking_price',newPrice)
                                        setQuortesyEvent(false)
                                    }
        
                                    if(players == 5){
                                        const newPrice = (parseFloat(booking_price) - 240)
                                        setFieldValue('booking_price',newPrice)
                                        setQuortesyEvent(false)
                                    }
        
                                    if(players == 6){
                                        const newPrice = (parseFloat(booking_price) - 220)
                                        setFieldValue('booking_price',newPrice)
                                        setQuortesyEvent(false)
                                    }
                                    
                                    if(players >= 7){
                                        const newPrice = (parseFloat(booking_price) - 200)
                                        setFieldValue('booking_price',newPrice)
                                        setQuortesyEvent(false)
                                    }
                                    
                                    
                                }
                            }else if(
                                giftCard.includes('FREE') ||
                                giftCard.includes('GIFT')){
                                    if(conf){
                                        setFieldValue('booking_price','0.00')
                                        setQuortesyEvent(false)
                                    }
                            }
                        }else{
                            setFieldValue('quortesy',"")
                            setQuortesyEvent(true)
                        }
                    }
                }else{
                    window.alert('La cortesía no se encuentra disponible')
                    setQuortesyEvent(true)
                }
            })
            .catch(error => {
                console.log(error,'error SUETJUAN1')
            })
        }
    }

    return(
        <ContainerWrapper fluid>
            <Row>
                <Col sm={6}>
                    <RowS players>
                        <Label>PERSONAS</Label>
                        <PlayerSelector 
                            players={values.players} 
                            onChange={(players) => {
                                let value = players
                                if(undefined !== players.value){ value = parseInt(players.value) }
                                setFieldValue('players',players)
                                /*switch(value){
                                    case 2: setFieldValue('booking_price','600.00'); break;
                                    case 3: setFieldValue('booking_price','840.00'); break;
                                    case 4: setFieldValue('booking_price','1040.00'); break;
                                    case 5: setFieldValue('booking_price','1200.00'); break;
                                    case 6: setFieldValue('booking_price','1320.00'); break;
                                    case 7: setFieldValue('booking_price','1400.00'); break;
                                    case 8: setFieldValue('booking_price','1600.00'); break;
                                }*/
                                setFieldValue('booking_price', `${value * COST_PER_PERSON}.00`)
                            }}
                            error={touched.players && errors.players}
                        />
                    </RowS>

                    <Input 
                        label={'Fecha'}
                        style={{
                            width: '100%'
                        }}
                        dateFormat="dd-MM-Y"
                        minDate={new Date()}
                        onChange={selected => {
                            setFieldValue('booking_date',selected)
                            setFieldValue('booking_room',"")
                            setSchedules([])
                        }}
                        selected={values.booking_date}
                        type={'date'}
                        labelStyle={{
                            paddingRight : "4rem"
                        }}
                        customInput={<CustomInput />}
                        error={touched.booking_date_format && errors.booking_date_format}
                    />

                    <Input 
                        label={'Habitación'}
                        onChange={room => {
                            setFieldValue('booking_room',room)
                            findRoomSchedules(room.value)
                        }}
                        value={values.booking_room}
                        type={'select'}
                        isLoading={loading}
                        isDisabled={loading}
                        options={rooms.map((room) => {
                            return {
                                value : room.room_id,
                                label : room.room_name
                            }
                        })}
                        error={touched.booking_room && errors.booking_room}
                    />

                    <Schedules
                        items={schedules}
                        loading={loading}
                        onChange={handleChange('booking_schedule')}
                        value={values.booking_schedule}
                        error={touched.booking_schedule && errors.booking_schedule}
                    />
                </Col>
                <Col sm={6}>
                    <RowS price>
                        <Label>PRECIO</Label>
                        <CurrencyFormat 
                            value={values.booking_price} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            renderText={value => <Label big>{`${value} MXN`}</Label>} />
                        
                    </RowS>
                    <RowS sb>
                        <Input 
                            label={'CORTESÍA'}
                            onChange={e => {
                                const quortesy = e.target.value.toString()
                                setFieldValue('quortesy',quortesy.toUpperCase())
                            }}
                            value={values.quortesy}
                            type={'text'}
                            labelStyle={{
                                paddingRight : "2rem"
                            }}
                            error={touched.quortesy && errors.quortesy}
                        />
                        <Button medium onClick={checkGiftCard} disabled={!canCheckQuortesy}>APLICAR</Button>
                    </RowS>

                    <Label disclaimer dangerouslySetInnerHTML={{__html:booking_message}} />
                </Col>
            </Row>
            <Row>
                <CustomCol>
                    <Button active onClick={handleSubmit}>Siguiente</Button>
                </CustomCol>
            </Row>
        </ContainerWrapper>
    )
}

const enhanced = withFormik({
    mapPropsToValues: props => {
        const { formValues } = props
        return {
          players : formValues.players || 2,
          booking_date : formValues.booking_date || new Date(),
          booking_room : formValues.booking_room || "",
          quortesy : formValues.quortesy || "",
          booking_schedule : formValues.booking_schedule || "",
          booking_price : formValues.booking_price || "$ 590.00"
        };
    },
    validateOnChange: true,
    validationSchema: props =>
    yup.object().shape({
        players : yup.string().required('Seleccione el número de jugadores'),
        booking_date : yup.string().required('Seleccione una fecha de reserva'),
        booking_room : yup.string().required('Seleccione un cuarto'),
        booking_schedule : yup.string().required('Seleccione un horario'),
        booking_price : yup.string().required('El precio se genera automáticamente')
    }),
    handleSubmit: (values, bag) => {
        const { setStatus, resetForm, props } = bag;        
        props.onNextStep(values)
    }
})

export default enhanced(ReservaForm)