import React, { useState } from 'react'
import { Col, Row, Container } from 'reactstrap'
import logo from '../assets/logo-euforia-web.png'
import styled, {css } from 'styled-components'
import { Menu } from '../components'
import { config } from '../settings'
import {
    isMobile,
    BrowserView,
    MobileView
} from "react-device-detect";

const MobileItemMenu = styled.a`
    color : ${({theme}) => theme.colors.white};
    text-decoration:none;
    cursor:pointer;
    font-size:14px;
    display:block;
    padding : 8px 16px;
    margin-bottom:8px;
    font-weight:bold;
`

const ContainerWrap = styled(Container)`
    padding : 1.25rem;
`
const Separator = styled.div`
    width:100%;
    height : 10px;
`

const MenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
`

const Brand = styled.img`
    @media only screen (min-width: 320px) and (max-width:640px) {
        width : 180px;
    }
`

const Line = styled.div`
    width : 23px;
    height : 2px;
    margin-bottom:4px;
    background-color:${({theme}) => theme.colors.white};
    cursor:pointer;
    margin-right:15px;
`

const HeaderWrap =  styled.div`
    @media (max-width: 48em) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 9;
        background: black;
    }
`
const Sidebar = styled.div`
    display:flex;
    flex:1;
    background-color:${({theme}) => theme.colors.black};
    width : 240px;
    position:fixed;
    left:-240px;
    top:60px;
    bottom:0;
    transition:all 300ms ease;
    z-index:9;
    flex-direction:column;

    ${({open}) => open && css`
        left:0;
    `}
`

const topList = [
    {
        href : `${config.url}#contacto`,
        title : 'Nosotros'
    },{
        href : `${config.url}`,
        title : 'FAQ'
    },{
        href : `${config.url}`,
        title :'ES'
    },{
        href : `${config.url}en`,
        title : 'EN'
    }
]

const menuItems = [
    {
        href : `${config.url}#que-es`,
        title : '¿Qué es?',
        big : true
    },{
        href : `${config.url}#cuartos`,
        title : 'Cuartos',
        big : true
    },{
        href : `${config.url}#precios`,
        title :'Precios',
        big : true
    },{
        href : `${config.url}#empresas`,
        title : 'Empresas',
        big : true
    },{
        href : `${config.url}#contacto`,
        title : 'Contacto',
        big : true
    },{
        href : `https://booking.euforiarooms.com`,
        title : 'Reservas',
        big : true
    }
]

const Header = () => {

    const [isMenuVisible, setMenuVisible] = useState(false)

    return(
        <HeaderWrap>
        <ContainerWrap fluid>
            <Row>
                <Col style={
                    isMobile ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                    } : {}
                }>
                    <MobileView style={{textAlign:'right',marginTop:5}}>
                        <div 
                            onClick={() => {
                                setMenuVisible(!isMenuVisible)
                            }}
                            style={{textAlign:'right', cursor:'pointer'}}>
                            <Line />
                            <Line />
                            <Line />
                        </div>
                    </MobileView>
                    <a href="https://booking.euforiarooms.com" title="Euforia Rooms">
                        <Brand src={logo} title="Euforia Rooms" />
                    </a>
                </Col>
                <Col>
                    <BrowserView>
                        <MenuWrapper>
                            <Menu items={topList} />
                            <Separator />
                            <Menu type="big" items={menuItems} />
                        </MenuWrapper>
                    </BrowserView>
                </Col>
            </Row>
        </ContainerWrap>
            <MobileView>
                <Sidebar open={isMenuVisible}>
                    {menuItems.map((link) => (
                        <MobileItemMenu {...link}>
                            {link.title}
                        </MobileItemMenu>
                    ))}
                </Sidebar>
            </MobileView>
        </HeaderWrap>
    )
}

export default Header