import React from 'react'
import { Header, Wizard } from '../components'
import { Col } from 'reactstrap' 
import styled, { css } from 'styled-components'


const Container = styled.div`
    display:flex;
    flex-direction:column;
    flex:1;
    background-color : ${({theme}) => theme.colors.black};
    background-image:url('https://euforiarooms.com/images/imagen-fondo-cuartos.jpg');
    background-repeat:no-repeat;
    background-size:cover;
`
const WizardWrap = styled(Col)`
    padding-top:50px;
`

const Booking = () => {
    return(
        <Container>
            <WizardWrap sm={12}>
                <Wizard />
            </WizardWrap>
        </Container>
    )
}

export default Booking