import React from 'react'
import styled from 'styled-components'

const Message = styled.span`
    color: red;
    font-size:14px;
    font-weight:bold;
    display:block;
`

const ErrorMessage = ({message}) => {
    return(
        <div>
            <Message>{message}</Message>
        </div>
    )
}

export default ErrorMessage