import { useEffect, useReducer, useState } from 'react';

import { api } from '../api';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, loading: true, error: null };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      throw new Error(
        `Unsupported action type ${action.type} for useQueryData`
      );
  }
};

type HookProps = {
  endpoint?: string;
  variables?: object;
  method?: string;
  trigger?: any;
  headers?: object;
};

const useQueryData = ({
  endpoint,
  variables = {},
  method = 'get',
  trigger,
  headers = {},
}: HookProps) => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: true,
    error: null,
    data: null,
  });

  const [refetchCount, setRefetchCount] = useState(0);
  const refetch = () => setRefetchCount(refetchCount + 1);

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const result = await api[method](endpoint, variables, headers);

        if (!didCancel) {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: result.data,
          });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', payload: error });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [trigger, refetchCount]);

  return [{ ...state }, refetch];
};

export default useQueryData;
