import React from 'react'
import styled, { css } from 'styled-components'

const List = styled.ul`
    list-style:none;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    margin:0;
    padding:0;

    ${({type}) => type === "small" && css`
        width : 170px;
    `}
`

const Item = styled.li`
    text-align:center;
    padding:0px 0.25rem;

    ${({big}) => big && css`
        padding : 0px 20px;
    `}

    &:last-child{
        padding:0;
    }
`

const Link = styled.a`
    color : ${({theme}) => theme.colors.white};
    display:block;
    text-decoration:none;
    font-size: 12px;
    font-weight:bold;
    transition:all 300ms ease;

    &:hover{
        color : ${({theme}) => theme.colors.yellow};
    }

    ${({big}) => big && css`
        font-size:14px;
        text-transform:uppercase;
    `}
`

const Menu = ({items, type}) => {
    return(
        <div>
            <List type={type}>
                {items.map((item) => (
                    <Item {...item}>
                        <Link {...item}>{item.title}</Link>
                    </Item>
                ))}
            </List>
        </div>
    )
}

Menu.defaultProps = {
    items : [],
    type : 'small'
}

export default Menu
