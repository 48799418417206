import React from 'react'
import styled, { css } from 'styled-components'
import {
    BrowserView,
    MobileView,
  } from "react-device-detect";
import { ErrorMessage, Input } from '../components'

const Item = styled.div`
    border:1px solid ${({theme}) => theme.colors.white};
    padding : 0.6rem 1.15rem;
    font-size:18px;
    text-transform:uppercase;
    color : ${({theme}) => theme.colors.white};
    margin-bottom:10px;
    transition:all 300ms ease;
    cursor:pointer;

    ${({disabled}) => !disabled && css`
       &:hover{
        border:1px solid ${({theme}) => theme.colors.yellow};
        color:${({theme}) => theme.colors.yellow};
       }
    `}

    ${({active}) => active && css`
        border:1px solid ${({theme}) => theme.colors.yellow};
        color:${({theme}) => theme.colors.yellow};
    `}

    ${({disabled}) => disabled && css`
        text-decoration: line-through;
        color :  ${({theme}) => theme.colors.lightGray};
        border:1px solid ${({theme}) => theme.colors.lightGray};
        cursor:not-allowed;
    `}
`

const Container = styled.div`
    display:flex;
    flex:1;
    align-items:center;
    flex-wrap:wrap;
    margin-top:15px;
    max-width:400px;
    justify-content:space-between;
`

const Schedules = ({onChange, error, value, loading, items}) => {
    return(
        <div>
            <BrowserView>
                <Container>
                    {items && items.map((schedule) => (
                        <Item 
                            disabled={schedule.status === "booked" || schedule.status === "blocked"}
                            active={value === schedule.value.substring(0,5)}
                            onClick={() => {
                                if(schedule.status !== 'booked' && schedule.status !== 'blocked'){
                                    onChange(schedule.value.substring(0,5))
                                }
                            }}
                        >
                            {schedule.label.substring(0,5)}
                        </Item>
                    ))}
                </Container>
                {error && <ErrorMessage message={error} />}
            </BrowserView>
            <MobileView>
                <Input 
                    label={'Horario'}
                    labelStyle={{
                        paddingRight:'2.8rem'
                    }}
                    isLoading={loading}
                    isDisabled={loading}
                    type={'select'}
                    onChange={onChange}
                    options={items.map(schedule => {
                        return {
                            value : schedule.value.substring(0,5),
                            label : schedule.label.substring(0,5),
                            status : schedule.status
                        }
                    })}
                    isOptionDisabled={option => option.status === "booked" || option.status === "blocked"}
                    value={value}
                    error={error}
                />
            </MobileView>
        </div>
    )
}

Schedules.defaultProps = {
    onChange : () => {}
}

export default Schedules