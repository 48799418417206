import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import {
    BrowserView,
    MobileView,
    isMobile,
  } from "react-device-detect";
import * as yup from 'yup'
import { withFormik } from 'formik'
import styled, {css} from 'styled-components'
import { Input, Button } from '../../components'
import moment from 'moment'
import 'moment/min/locales'
const ContainerWrapper = styled(Container)`
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;

    @media (max-width: 48em) {
        padding:20px !important;
    }
`;

const Label = styled.label`
    color : ${({theme}) => theme.colors.white};
    font-size:15px;
    text-transform:uppercase;
    padding-right:1rem;
    display:block;

    ${({big}) => big && css`
        font-size:32px;
        font-weight:bold;
        color : ${({theme}) => theme.colors.yellow};
        @media (max-width: 48em) {
            margin-top:20px;
        }
    `}

    ${({medium}) => medium && css`
        font-size:18px;
        font-weight:bold;
        color : ${({theme}) => theme.colors.white};
    `}

    ${({center}) => center && css`
        text-align:center;
    `}

    ${({right}) => right && css`
        text-align:right;
    `}
`

const Separator = styled.div`
    height : 30px;
`

const CustomCol = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    flex-direction:column;
`

const RoomInfoWrap = isMobile ? CustomCol : Row 

const ContactForm = ({
    values,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    formValues,
    goBack
}) => {
    moment.locale('es')

    return(
        <ContainerWrapper fluid>
            <Row>
                <Col sm={5}>
                    <Input 
                        label={"NOMBRE"}
                        value={values.booking_name}
                        error={touched.booking_name && errors.booking_name}
                        onChange={e => {
                            const bookingName = e.target.value.toString()
                            setFieldValue('booking_name',bookingName.toUpperCase())
                        }}
                    />

                    <Separator />

                    <Input 
                        label={"CELULAR"}
                        value={values.booking_phone}
                        error={touched.booking_phone && errors.booking_phone}
                        onChange={handleChange('booking_phone')}
                    />

                    <Separator />

                    <Input 
                        label={"EMAIL"}
                        labelStyle={{
                            paddingRight : "2.9rem"
                        }}
                        value={values.booking_email}
                        error={touched.booking_email && errors.booking_email}
                        onChange={handleChange('booking_email')}
                    />
                </Col>
                <Col sm={2}></Col>
                <Col sm={5}>
                    <Label big>{formValues.booking_room.label}</Label>
                    <Separator />
                    <RoomInfoWrap>
                        
                            <Label medium>{moment(formValues.booking_date).format('dddd, DD MMM [de] Y')}</Label>
                        
                            <Label medium center>{formValues.booking_schedule && formValues.booking_schedule.value || formValues.booking_schedule}</Label>
                        
                            <Label medium right>{`${formValues.players && formValues.players.value || formValues.players} JUGADORES`}</Label>
                        
                    </RoomInfoWrap>
                </Col>
            </Row>
            <Separator />
            <Row>
                <Col>
                    <Button gray onClick={goBack}>VOLVER</Button>
                </Col>
                <Col style={{textAlign : "right"}}>
                    <Button active onClick={handleSubmit}>SIGUIENTE</Button>
                </Col>
            </Row>
        </ContainerWrapper>
    )
}

const enhanced = withFormik({
    mapPropsToValues: props => {
        const { formValues } = props
        return {
            booking_name : formValues.booking_name || "",
            booking_phone : formValues.booking_phone || "",
            booking_email : formValues.booking_email || ""
        };
    },
    validateOnChange: true,
    validationSchema: props =>
    yup.object().shape({
        booking_name : yup.string().required('Escriba su nombre'),
        booking_phone : yup.string().required('Escriba su celular'),
        booking_email : yup.string().email().required('Escriba su email')
    }),
    handleSubmit: (values, bag) => {
        const { setStatus, resetForm, props } = bag;
        
        props.onNextStep(values)
    }
})

export default enhanced(ContactForm)