import React from 'react'
import styled from 'styled-components'
import Select from 'react-select';
import DatePicker, { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import { ErrorMessage } from '../components'
import { colors } from '../settings'

const DateWrapper = styled.div`
    width:100%;
    .react-datepicker-wrapper{
        width:100%;
    }
`

const Row = styled.div`
    display:flex;
    align-items:center;
    flex-direction:row;
    padding-bottom:0.5rem;
    width:100%;
`

const Label = styled.label`
    color : ${({theme}) => theme.colors.white};
    font-size:15px;
    text-transform:uppercase;
    padding-right:1.5rem;
    margin:0 !important;
`

const WrapInput = styled.input`
    background-color: ${({theme}) => theme.colors.dark} !important;
    border-radius:0 !important;
    font-size:15px !important;
    height : 40px !important;
    color : ${({theme}) => theme.colors.yellow} !important;
    box-shadow:none !important;
    border:0 !important;
    border-bottom:1px solid ${({theme}) => theme.colors.white} !important;
    width:100%;
`

const Input = ({label, labelStyle, ...props}) => {
    registerLocale('es', es)

    return(
        <div style={{width:'100%'}}>
        <Row>
            <Label style={labelStyle}>{label}</Label>
            {props.type !== "select" && props.type !== "date" && (<WrapInput {...props} />)}
            {props.type === "select" && (
                <div style={{width:'100%'}}>
                <Select {...props} styles={{ 
                    control: styles => ({ 
                        ...styles, 
                        backgroundColor: colors.dark, 
                        width: '100%',
                        maxWidth : '100%',
                        border:0,
                        borderBottom : `1px solid ${colors.white}`,
                        borderRadius :0,
                        color : colors.white
                    }),
                    input: styles => ({ ...styles, color : colors.white }),
                    placeholder: styles => ({ ...styles, color : colors.yellow}),
                    singleValue: (styles, { data }) => ({ ...styles, color : colors.yellow }),
                }} />
                </div>
            )}
            {props.type === "date" && <DateWrapper><DatePicker locale="es" {...props} /></DateWrapper>}
        </Row>
        {props.error && <ErrorMessage message={props.error} />}
        </div>
    )
}

Input.defaultProps = {
    onChange : () => {},
    label : ''
}

export default Input