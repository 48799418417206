import styled, { css } from 'styled-components'

const Button = styled.button`
    border:1px solid ${({theme}) => theme.colors.white};
    padding : 0.6rem 1.15rem;
    font-size:18px;
    text-transform:uppercase;
    color : ${({theme}) => theme.colors.white};
    margin-bottom:10px;
    transition:all 300ms ease;
    cursor:pointer;
    background-color:transparent;
    color : ${({theme}) => theme.colors.white};

    &:hover{
        background-color:${({theme}) => theme.colors.yellow};
        color : ${({theme}) => theme.colors.black};
        border:1px solid ${({theme}) => theme.colors.yellow};
    }

    ${({medium}) => medium && css`
        padding : 0.3rem 0.9rem;
        font-size:15px;
    `}

    ${({active}) => active && css`
        background-color:${({theme}) => theme.colors.yellow};
        color : ${({theme}) => theme.colors.black};
        border:1px solid ${({theme}) => theme.colors.yellow};
        font-weight:bold;

        &:hover{
            background-color:${({theme}) => theme.colors.black};
            color : ${({theme}) => theme.colors.yellow};
            border:1px solid ${({theme}) => theme.colors.black};
        }
    `}

    ${({disabled}) => disabled && css`
        opacity : 0.5;
        cursor:not-allowed;
        color :  ${({theme}) => theme.colors.lightGray};
        border:1px solid ${({theme}) => theme.colors.lightGray};
    `}

    ${({gray}) => gray && css`
        background-color:${({theme}) => theme.colors.gray};
        color : ${({theme}) => theme.colors.black};
        border:1px solid ${({theme}) => theme.colors.gray};
        font-weight:bold;

        &:hover{
            background-color:${({theme}) => theme.colors.black};
            color : ${({theme}) => theme.colors.yellow};
            border:1px solid ${({theme}) => theme.colors.black};
        }
    `}
`

export default Button