const USER = 'euforia.user.key';
const ADMIN = 'euforia.admin.impersonating.key';

const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const getItem = key => {
  const item = window.localStorage.getItem(key);
  if (!item) return null;

  return JSON.parse(window.localStorage.getItem(key));
};

const removeItem = (key, value) => {
  window.localStorage.removeItem(key, value);
};

export default {
    USER, ADMIN, setItem, getItem, removeItem
}