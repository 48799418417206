import { colors } from '../settings';
import dimensions from './dimensions';

export default {
  fonts: {
    Arial: 'Arial',
  },
  colors: {
    ...colors,
  },
  dimensions: {
    ...dimensions,
  },
  media: {
    ...dimensions.media,
  },
  generateBreakpoint: {
    ...dimensions.generateBreakpoint,
  },
};