import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import {
    isMobile,
  } from "react-device-detect";
import styled, { css } from 'styled-components'
import moment from 'moment'
import 'moment/min/locales'
import { Button } from '../../components'
import CurrencyFormat from 'react-currency-format';
import useQueryData from '../../hooks/useQueryData'

const CustomCol = styled(Col)`
    text-align:right;
    padding:0 !important;
    padding-top:40px !important;
    @media (max-width: 48em) {
        text-align:center;
        margin-top:0px;
        padding:0;
    }

    ${({small}) => small && css`
        @media (max-width:48em){
            padding-top:20px !important;
            display:flex;
            flex-direction:column;
            align-items: flex-start;
        }
    `}
`

const Separator = styled.div`
    height : 30px;

    @media (max-width: 48em) {
        height:20px;
     }
`

const Label = styled.label`
    color : ${({theme}) => theme.colors.white};
    font-size:15px;
    text-transform:uppercase;
    padding-right:1rem;

    ${({medium}) => medium && css`
        font-weight : bold;
        font-size:20px;
        padding:0;
    `}

    ${({small}) => small && css`
        font-weight : bold;
        font-size:14px;
        padding:0;
    `}

    ${({big}) => big && css`
        font-size:32px;
        font-weight:bold;
        color : ${({theme}) => theme.colors.yellow};
        paddingLeft : 0.9em;

        @media (max-width: 48em) {
           padding:0;
           font-size:25px
        }
    `}

    ${({space}) => space && css`
        @media (max-width: 48em) {
            padding-top:20px;
        }
    `}

    ${({disclaimer}) => disclaimer && css`
        font-size:15px;
        font-style:italic;
        padding:0;
        text-transform:none !important;
        color : ${({theme}) => theme.colors.white};
    `}

    ${({smallCentered}) => smallCentered && css`
        @media (max-width: 48em) {
            text-align:center;
        }
    `}

    ${({center}) => center && css`
        text-align:center;
    `}

    ${({right}) => right && css`
        text-align:right;
    `}

    ${({yellow}) => yellow && css`
        color : ${({theme}) => theme.colors.yellow};
        margin-left:0.5rem;
    `}
`

const ContainerWrapper = styled(Container)`
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;

    @media (max-width: 48em) {
        padding:20px !important;
    }
`;

const RowS = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    padding-bottom:0.5rem;
    width:100%;

    ${({sb}) => sb && css`
        justify-content:space-between;
    `}

    ${({price}) => price && css`
        @media (max-width: 48em) {
            flex-direction:row !important;
            justify-content:space-between !important;
        }
    `}

    @media (max-width: 48em) {
        flex-direction:column;
    }

    ${({players}) => players && css`
        @media (max-width: 48em) {
            flex-direction:row !important;
        }
    `}
`

const Rows = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
`
const RoomInfoWrap = isMobile ? CustomCol : Rows 


const BookingForm = ({
    formValues
}) => {
    moment.locale('es')


    const [ {data, loading, error} ] = useQueryData({
        endpoint : '/traduction/21/1',
        trigger : null
    })

    const checkout_message = data && data.result && data.result.checkout_content || "Espere por favor ..."


    const formatedDate = moment(formValues.booking_date).format('DDMMYY')
    const formatedHour = (formValues.booking_schedule && formValues.booking_schedule.value || formValues.booking_schedule).replace(/:/g,'')
    const booking_code = `${formValues.booking_room.value}-${formatedHour}-${formatedDate}`

    return(
        <ContainerWrapper fluid>
            <Row>
                <Col sm={5}>
                    
                    <Label big>¡RESERVA CONFIRMADA!</Label>
                    <Separator />
                    <Label medium>RESERVA : {booking_code}</Label>

                    <CustomCol>
                        <RowS>
                            <Label small>NOMBRE</Label>
                            <Label small yellow>{`${formValues.booking_name}`}</Label>
                        </RowS>
                        <RowS>
                            <Label small>EMAIL</Label>
                            <Label small yellow>{`${formValues.booking_email}`}</Label>
                        </RowS>
                        {formValues.quortesy && 
                        <RowS>
                            <Label small>CORTESÍA</Label>
                            <Label small yellow>{`${formValues.quortesy}`}</Label>
                        </RowS>}
                        <RowS>
                            <Label small>PRECIO</Label>
                            <CurrencyFormat 
                            value={formValues.booking_price} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            renderText={value => <Label small yellow>{`${value} MXN`}</Label>
                        } />
                        </RowS>
                        <RowS>
                            <Label small>PAGO</Label>
                            <Label small yellow>{`${formValues.booking_payment_method.label}`}</Label>
                        </RowS>
                    </CustomCol>

                    <Separator />
                    <Label medium smallCentered>YA ENVIAMOS ESTA RESERVACIÓN A TU CORREO</Label>
                </Col>
                <Col sm={2}></Col>
                <Col sm={5}>
                    <Label big space>{`${formValues.booking_room.label}`}</Label>
                    <Separator />
                    <RoomInfoWrap small>
                        
                            <Label medium>{moment(formValues.booking_date).format('dddd, DD MMM [de] Y')}</Label>
                        
                            <Label medium center>{`${formValues.booking_schedule && formValues.booking_schedule.value || formValues.booking_schedule}`}</Label>
                        
                            <Label medium right>{`${formValues.players && formValues.players.value || formValues.players} JUGADORES`}</Label>
                    </RoomInfoWrap>
                    <Separator />

                    <Label medium yellow>DATOS IMPORTANTES</Label>

                    <Label disclaimer dangerouslySetInnerHTML={{__html:checkout_message}} />
                    <Separator />
                </Col>
            </Row>
            <Row>
                <CustomCol>
                    <Button active onClick={() => {
                        window.location.reload()
                    }}>TERMINAR</Button>
                </CustomCol>
            </Row>
        </ContainerWrapper>
    )
}

export default BookingForm