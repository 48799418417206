import { config } from '../settings';
import axios from 'axios';
import storage from '../settings/storage'

const ApiClient = axios.create({
  baseURL: config.api,
  responseType: 'json',
});

ApiClient.defaults.headers.post['Content-Type'] = 'application/json';

ApiClient.interceptors.request.use(
  async config => {
    const authToken = await storage.getItem('access_token');
    if (authToken) config.headers['Authorization'] = `Bearer ${authToken}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default ApiClient;
