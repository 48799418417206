import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
    BrowserView,
    MobileView,
  } from "react-device-detect";
import { ErrorMessage, Input } from '../components'

const Wrapper = styled.div`
    padding : 0.5rem;
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:flex-start;
    width:100%;

    @media (max-width: 48em) {
        padding:0;
    }
`

const OptsWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    flex-direction:row;
`

const Circle = styled.div`
    width : 38px;
    height:38px;
    border-radius:19px;
    border:1px solid ${({theme}) => theme.colors.yellow};
    text-align:center;
    margin: 1px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: ${({theme}) => theme.colors.yellow};
    cursor: pointer;
    transition : all 300ms ease;

    &:hover{
        background-color : ${({theme}) => theme.colors.darkLight};
        color : ${({theme}) => theme.colors.white};
    }
`

const playersN = [2,3,4,5,6,7,8]

const PlayerSelector = ({players, onChange, error}) => {

    const [isOpened, setOpened] = useState(false)

    return(
        <Wrapper>
            <BrowserView>
                <Wrapper>
                <Circle onClick={() => setOpened(!isOpened)}>{players}</Circle>
                
                {isOpened && (
                    <OptsWrapper>
                        {playersN.map((player) => <Circle onClick={() => {
                            onChange(player)
                            setOpened(false)
                        }}>{player}</Circle>)}
                    </OptsWrapper>
                )}
                {error && <ErrorMessage message={error} />}
                </Wrapper>
            </BrowserView>

            <MobileView style={{width:'100%'}}>
                <Input 
                    type={'select'}
                    onChange={onChange}
                    value={players}
                    options={[
                        {
                            value : "2",
                            label : "2"
                        },
                        {
                            value : "3",
                            label : "3"
                        },
                        {
                            value : "4",
                            label : "4"
                        },
                        {
                            value : "5",
                            label : "5"
                        },
                        {
                            value : "6",
                            label : "6"
                        },
                        {
                            value : "7",
                            label : "7"
                        },
                        {
                            value : "8",
                            label : "8"
                        }
                    ]}
                    error={error}
                />
            </MobileView>

            
        </Wrapper>
    )
}

PlayerSelector.defaultProps = {
    onChange : () => {},
    error : null,
    players : 1
}

export default PlayerSelector