import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Booking } from './routes';
import { theme } from './settings';
import * as serviceWorker from './serviceWorker';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: scroll;
  }
  
  #root{
    display: flex;
    flex: 1;
    width: 100%;
    height: 100vh;
  }
  body {
    padding: 0;
    margin: 0;
    display: flex;
    flex: 1;
    min-width: ${({ theme }) => theme.dimensions.siteMinWidth};
    margin: auto;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    background-color: ${({ theme }) => theme.colors.black} !important;
  }
`;

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
            <GlobalStyle />
            <div id="router" style={{ display: 'flex', flex: 1 }}>
                <Booking />
            </div>
        </>
      </ThemeProvider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
