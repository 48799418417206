import React,  { useState } from 'react'
import styled, { css } from 'styled-components'
import { ReservaForm, ContactForm, CheckoutForm, BookingForm } from '../components/forms'
import { api } from '../api'
import moment from 'moment'

const WizardTab = styled.div`
    background-color:${({theme}) => theme.colors.dark};
    position:relative;
    padding: 1.7em 1em !important;
    text-transform: uppercase;
    font-size: 18px !important;
    text-align: center;
    display:flex;
    flex:1;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    background-color:${({theme}) => theme.colors.darkLight};
    color : ${({theme}) => theme.colors.gray};

    ${({current}) => current && css`
        color: ${({theme}) => theme.colors.yellow};
        background-color: ${({theme}) => theme.colors.dark};
        &:after{
            content: " ";
            width: 65%;
            height: 2px;
            background-color: ${({theme}) => theme.colors.yellow};
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    `}

    @media (max-width: 48em) {
        width:100%;
    }
`

const FormContainer = styled.div`
    padding-top:2.5rem;
    padding-bottom:2.5rem;
`

const WizardStepsWrap = styled.div`
    display:flex;
    background-color:${({theme}) => theme.colors.dark};
    flex-wrap:wrap;

    .tab{
        order:99;
        width:100%;
    }

    @media (max-width: 48em) {
        flex-direction:column;
        .tab
        {
          order:initial;
        }
        .tabs ${WizardTab} {
          width: 100%;
        }
    }
`

const steps = [{
    tab : 1,
    title : "1 . RESERVA"
},{
    tab : 2,
    title : "2 . CONTACTO"
},{
    tab : 3,
    title : "3 . CHECKOUT"
},{
    tab : 4,
    title : "4 . CONFIRMACIÓN"
}]

const Wizard = () => {

    const [ tab, setTab ] = useState(1)

    const isTabActive = current => tab === current;

    const handleTabChange = tab => setTab(tab)

    const [ formValues, setFormValues ] = useState({})
    const [ booking, setBooking ] = useState(false)

    const saveFormValues = values => setFormValues({
        ...formValues,
        ...values
    })

    const makeBooking = () => {
        setBooking(true)

        var bodyFormData = new FormData();

        bodyFormData.append('request','request')
        bodyFormData.append('method','sale')
        bodyFormData.append('hora',formValues.booking_schedule && formValues.booking_schedule.value || formValues.booking_schedule)
        bodyFormData.append('date',moment(formValues.booking_date).format('YY-MM-DD'))
        bodyFormData.append('room_id',formValues.booking_room.value)
        bodyFormData.append('players',formValues.players && formValues.players.value || formValues.players)
        bodyFormData.append('cortesia',formValues.quortesy)
        bodyFormData.append('userName',formValues.booking_name)
        bodyFormData.append('userEmial',formValues.booking_email)
        bodyFormData.append('userPhone',formValues.booking_phone)

        const unformatedAmount = formValues.booking_price.replace('$','').replace(',','').replace('MXN','');
        const amount = parseFloat(unformatedAmount);

        bodyFormData.append('amount',amount)
        bodyFormData.append('type',formValues.booking_payment_method && formValues.booking_payment_method.value || "local")

        api.post('vendor/ajaxPost.php',bodyFormData,{
            baseURL : 'https://euforiarooms.com/'
        }).then(({data}) => {
            const { result, response, message } = data
            if(response){
                setBooking(false)
                handleTabChange(4)
            }else{
                setBooking(true)
                alert(`${message}`)
                handleTabChange(1)
            }
        })
        .catch(error => {
            console.log(error,'booking error')
            setBooking(false)
        })
    }

    return(
        <WizardStepsWrap>

            
                <WizardTab current={isTabActive(1)}>
                    <span>1 . RESERVA</span>
                </WizardTab>
                {tab === 1 && <FormContainer className="tab">
                    <ReservaForm 
                        formValues={formValues} 
                        onNextStep={(values) => {
                            saveFormValues(values);
                            handleTabChange(2)
                        }} 
                    />
                </FormContainer>}
            
            
                <WizardTab current={isTabActive(2)}>
                    <span>2 . CONTACTO</span>
                </WizardTab>
                {tab === 2 && <FormContainer className="tab">
                 <ContactForm 
                    goBack={() => handleTabChange(1)} 
                    formValues={formValues} 
                    onNextStep={(values) => {
                        saveFormValues(values)
                        handleTabChange(3)
                    }} 
                />
                </FormContainer>}

                <WizardTab current={isTabActive(3)}>
                    <span>3 . CHECKOUT</span>
                </WizardTab>
                {tab === 3 && <FormContainer className="tab">
                 <CheckoutForm 
                    goBack={() => handleTabChange(2)} 
                    sending={booking}
                    formValues={formValues} 
                    onNextStep={(values) => {
                        saveFormValues(values)
                        setTimeout(() => {
                            makeBooking()
                        },200)
                    }}
                />
                </FormContainer>}

                <WizardTab current={isTabActive(4)}>
                    <span>4 . CONFIRMACION</span>
                </WizardTab>
                {tab === 4 && <FormContainer className="tab">
                    <BookingForm 
                        formValues={formValues} 
                    />
                </FormContainer>}

            

            

        </WizardStepsWrap>
    )
}

export default Wizard