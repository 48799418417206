import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import {
    BrowserView,
    MobileView,
    isMobile,
  } from "react-device-detect";
import * as yup from 'yup'
import { withFormik } from 'formik'
import styled, {css} from 'styled-components'
import { Input, Button } from '../../components'
import moment from 'moment'
import 'moment/min/locales'
import CurrencyFormat from 'react-currency-format';


const ContainerWrapper = styled(Container)`
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;

    @media (max-width: 48em) {
        padding:20px !important;
    }
`;

const Label = styled.label`
    color : ${({theme}) => theme.colors.white};
    font-size:15px;
    text-transform:uppercase;
    padding-right:1rem;
    display:block;

    ${({big}) => big && css`
        font-size:32px;
        font-weight:bold;
        color : ${({theme}) => theme.colors.yellow};

        @media (max-width: 48em) {
            margin-top:20px;
            font-size:28px;
        }
    `}

    ${({medium}) => medium && css`
        font-size:18px;
        font-weight:bold;
        color : ${({theme}) => theme.colors.white};
    `}

    ${({center}) => center && css`
        text-align:center;
    `}

    ${({right}) => right && css`
        text-align:right;
    `}

    ${({yellow}) => yellow && css`
        color : ${({theme}) => theme.colors.yellow};
        margin-left:0.5rem;
    `}

    @media (max-width: 48em) {
        margin-right:0 !important;
    }
`

const Separator = styled.div`
    height : 30px;
`

const CustomCol = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    flex-direction:column;
`

const Rows = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-start;
`


const RoomInfoWrap = isMobile ? CustomCol : Row 

const CheckoutForm = ({
    values,
    formValues,
    errors,
    touched,
    handleSubmit,
    handleChange,
    goBack,
    sending
}) => {
    moment.locale('es')
    return(
        <ContainerWrapper fluid>
            <Row>
                <Col sm={6}>
                    <Rows>
                        <Label>Nombre</Label>
                        <Label yellow>{formValues.booking_name}</Label>
                    </Rows>
                    <Rows>
                        <Label>Celular</Label>
                        <Label yellow>{formValues.booking_phone}</Label>
                    </Rows>
                    <Rows>
                        <Label>Email</Label>
                        <Label yellow>{formValues.booking_email}</Label>
                    </Rows>
                    {formValues.quortesy && (
                        <Rows>
                            <Label>Cortesía</Label>
                            <Label yellow>{formValues.quortesy}</Label>
                        </Rows>
                    )}
                </Col>
                <Col sm={6}>
                    <Label big>{formValues.booking_room.label}</Label>
                    <Separator />
                    <RoomInfoWrap>
                        
                            <Label medium>{moment(formValues.booking_date).format('dddd, DD MMM [de] Y')}</Label>
                        
                            <Label medium center>{formValues.booking_schedule && formValues.booking_schedule.value || formValues.booking_schedule}</Label>
                        
                            <Label medium right>{`${formValues.players && formValues.players.value || formValues.players} JUGADORES`}</Label>
                    </RoomInfoWrap>

                    <Separator />
                    
                    <Input 
                        label={'Pago'}
                        onChange={handleChange('booking_payment_method')}
                        value={values.booking_payment_method}
                        type={'select'}
                        options={[
                            {
                                value : "local",
                                label : "En el Local"
                            }
                        ]}
                        error={touched.booking_payment_method && errors.booking_payment_method}
                    />

                    <Separator />

                    <Row style={{
                        paddingLeft : '0.9rem'
                    }}>
                        <Label>PRECIO</Label>
                        <CurrencyFormat 
                            value={formValues.booking_price} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$'} 
                            renderText={value => <Label big style={{
                                paddingLeft : '0.9em',
                                margin:0
                            }}>{`${value} MXN`}</Label>} />
                        
                    </Row>
                </Col>
            </Row>
            <Separator />
            <Row>
                <Col>
                    <Button gray onClick={goBack}>VOLVER</Button>
                </Col>
                <Col style={{textAlign : "right"}}>
                    <Button 
                        active 
                        onClick={() => {
                            if(!sending){
                                handleSubmit()
                            }
                        }}
                        disabled={sending}
                    >SIGUIENTE</Button>
                </Col>
            </Row>
        </ContainerWrapper>
    )
}

const enhanced = withFormik({
    mapPropsToValues: props => {
        const { formValues } = props
        return {
            booking_payment_method : formValues.booking_payment_method || ""
        };
    },
    validateOnChange: true,
    validationSchema: props =>
    yup.object().shape({
        booking_payment_method : yup.string().required('Elija un método de reservación'),
    }),
    handleSubmit: (values, bag) => {
        const { setStatus, resetForm, props } = bag;
        
        props.onNextStep(values)
    }
})

export default enhanced(CheckoutForm)